import Header from "../Component/Navbar/Header";
import "../Css/Policy.css";
import Footer from "../Component/Footer/FooterSec";
const Policy = () => {
  return (
    <>
      <Header />
      <section className="policy-Page-Section-Banner-Images">
        <div className="policy-Page-Section-Banner-Images-con">
          <h2>Privacy & Policy</h2>
        </div>
      </section>
      <section className="section-policy">
        <div className="container">
          <div className="section-policy-main-con">
            <div className="section-policy-details-main-con">
              <h3>Resort Policy</h3>
              <p>Welcome to Royal Resort! Your privacy is very important to us. This policy explains what information we collect, how we use it, and how we keep it safe.</p>
            </div>
            <div className="section-policy-details-main-con">
              <h3>Information We Collect:</h3>
              <h4>We may collect:</h4>
              <p>
                <span>Personal Details: </span> Your name, email, phone number, payment details, and other information you provide when booking or contacting us.
              </p>
              <p>
                <span>Device & Browsing Info: </span> Your IP address, browser type, and device details when you visit our website.
              </p>
              <p>
                <span>Cookies & Tracking Data: </span> We use cookies to improve your experience and analyze website traffic.
              </p>
            </div>
            <div className="section-policy-details-main-con">
              <h3>How We Use Your Information</h3>
              <h4>We use your information to:</h4>
              <p>Process bookings and payments.</p>
              <p>Provide customer support and respond to your questions.</p>
              <p>Send promotions, newsletters, and updates (you can unsubscribe anytime).</p>
              <p>Improve our website and services.</p>
              <p>Follow legal rules and regulations.</p>
            </div>
            <div className="section-policy-details-main-con">
              <h3>Sharing Your Information</h3>
              <h4>We do not sell or rent your information. However, we may share it with:</h4>
              <p>
                <span>Service Providers:</span>  Companies that help us with bookings, payments, or website analytics.
              </p>
              <p>
                <span>Legal Authorities:</span> If required by law or to protect our rights.
              </p>
              <p>
                <span>Business Partners:</span> If we merge, sell, or transfer our business.
              </p>
            </div>
            <div className="section-policy-details-main-con">
              <h3>Links to Other Websites</h3>
              <p>
                Our website may have links to other sites. We are not responsible for their privacy policies, so please check their terms before sharing information.
              </p>
            </div>
            <div className="section-policy-details-main-con">
              <h3> Policy Updates:</h3>
              <p>
                We may update this policy from time to time. Any changes will be posted here with the updated date.
              </p>
            </div>
            <div className="section-policy-details-main-con">
              <h3>Contact Us</h3>
              <p>
                If you have any questions, please contact us.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Policy;