import FooterSec from "../Component/Footer/FooterSec";
import Header from "../Component/Navbar/Header";
import "../Css/Policy.css";
const TermsConditions = (() => {
    return (
        <>
            <Header />
            <section className="policy-Page-Section-Banner-Images">
                <div className="policy-Page-Section-Banner-Images-con">
                    <h2>Terms & Conditions</h2>
                </div>
            </section>
            <section className="section-policy">
                <div className="container">
                    <div className="section-policy-main-con">
                        <div className="section-policy-details-main-con">
                            <h3>Resort Policy</h3>
                            <p>Guests must present a photo ID and proof of address at check-in (Aadhaar, Driving Licence, PAN, Passport; foreign nationals: Passport/Visa if applicable).</p>
                            <p>check-in starts at 12:00 AM.</p>
                            <p>check-out is at 10:00 AM.</p>
                            <p>
                                Guests should check out promptly or notify reception if they wish to extend their stay. Extensions depend on availability and will be charged at the standard rate.
                            </p>
                        </div>
                        <div className="section-policy-details-main-con">
                            <h3>Tariff and Bill Settlement:</h3>
                            <p>
                                The tariff includes the room and meals only; additional meals and services incur extra charges. For inquiries, contact reception. Payments must be made upon arrival; personal cheques are not accepted.
                            </p>
                        </div>
                        <div className="section-policy-details-main-con">
                            <h3>Swimming Pool Rules:</h3>
                            <p>
                                Proper swimwear is mandatory in the pool. Children must be supervised by an adult. Management is not liable for loss or damage to personal belongings.
                            </p>
                        </div>
                        <div className="section-policy-details-main-con">
                            <h3>Illegal Substances:</h3>
                            <p>
                                The possession or use of illegal drugs/substances is strictly prohibited and will result in legal action.
                            </p>
                        </div>
                        <div className="section-policy-details-main-con">
                            <h3>Cancellation Policy:</h3>
                            <p>
                                Cancellations made at least one week before arrival will receive a full refund; cancellations made at least three days prior will receive a 50% refund.
                            </p>
                        </div>
                        <div className="section-policy-details-main-con">
                            <h3>Reservation Policy:</h3>
                            <p>
                                To confirm a booking, 50% of the total stay amount must be paid upfront, with the remaining 50% due at check-in.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <FooterSec />
        </>
    )
});


export default TermsConditions;